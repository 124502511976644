import { SchoolModelCurriculum } from './school.model';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from '@angular/fire/firestore';

export interface SchoolStudentAssessmentModel {
  assessment_date: SchoolStudentAssessmentDateModel;
  curriculum: SchoolModelCurriculum;
  class_ref: DocumentReference;
  grade: string;
  section: string;
  roll: number;
  total_marks: number;
  scored_marks: number;
  student_id: string;
  question_set: string;
  school_id: string;
  subject: string;
  teacher_id: string;
  remarks: string;
  ml_processed: boolean;
  created_at: Date;
  questions: SchoolStudentAssessmentQuestionModel[];
  chapter: string;
  topic: string;
  title: string;
  board: string;
  skills: string[];
  recommendations: StudentRecommendationModel[];
}

export interface SchoolStudentAssessmentDateModel {
  day: number;
  month: number;
  year: number;
}

export interface SchoolStudentAssessmentQuestionModel {
  score_number: number;
  max_marks: number;
  time_taken: number;
  max_time: number;
  question_id: string;
  type: string;
  marked_options: string[];
  correct: boolean;
}

export interface StudentRecommendationModel {
  grade: string;
  chapter: string;
  topic: string;
  subject: string;
}

export const SchoolStudentAssessmentModelConverter = {
  toFirestore: function (assessmentModel: SchoolStudentAssessmentModel) {
    return {
      assessment_date: assessmentModel.assessment_date,
      curriculum: assessmentModel.curriculum,
      class_ref: assessmentModel.class_ref,
      grade: assessmentModel.grade,
      section: assessmentModel.section,
      roll: assessmentModel.roll,
      total_marks: assessmentModel.total_marks,
      scored_marks: assessmentModel.scored_marks,
      student_id: assessmentModel.student_id,
      question_set: assessmentModel.question_set,
      school_id: assessmentModel.school_id,
      subject: assessmentModel.subject,
      teacher_id: assessmentModel.teacher_id,
      remarks: assessmentModel.remarks,
      ml_processed: assessmentModel.ml_processed,
      created_at: Timestamp.fromDate(assessmentModel.created_at),
      questions: assessmentModel.questions,
      chapter: assessmentModel.chapter,
      topic: assessmentModel.topic,
      title: assessmentModel.title,
      board: assessmentModel.board,
      skills: assessmentModel.skills,
      recommendations: assessmentModel.recommendations,
    };
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const assessmentModel: SchoolStudentAssessmentModel = {
      assessment_date: {
        day: data['assessment_date'].day,
        month: data['assessment_date'].month,
        year: data['assessment_date'].year,
      },
      curriculum: data['curriculum'],
      class_ref: data['class_ref'],
      grade: data['grade'],
      section: data['section'],
      roll: data['roll'],
      total_marks: data['total_marks'],
      scored_marks: data['scored_marks'],
      student_id: data['student_id'],
      question_set: data['question_set'],
      school_id: data['school_id'],
      subject: data['subject'],
      teacher_id: data['teacher_id'],
      remarks: data['remarks'],
      ml_processed: data['ml_processed'],
      created_at: data['created_at'].toDate(),
      questions: data['questions'],
      chapter: data['chapter'],
      topic: data['topic'],
      title: data['title'],
      board: data['board'],
      skills: data['skills'],
      recommendations: data['recommendations'],
    };
    return assessmentModel;
  },
};
