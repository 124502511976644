import {
  DocumentReference,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from '@angular/fire/firestore';
import { SchoolClassModel } from './school-class.model';
import { UserModel } from './user.model';

export interface SchoolStudentModel {
  school_id: string;
  user_id: string;
  grade: string;
  section: string;
  enrollment_number: string;
  roll: number;
  class_ref: DocumentReference | null;
  created_at: Date | null;
  active: boolean;
  deleted: boolean;
  house_id: string;
  team_id: string;
  council_id: string;
  features: string[];
}

export interface SchoolStudentDetailsModel {
  student_doc: SchoolStudentModel;
  student_details: UserModel;
}

export interface SuperAdminStudentTable {
  name: string;
  class_name: string;
  school: string;
  gender: string;
  roll: number;
}

export interface SchoolStudentTable {
  name: string;
  class: string;
  roll: number;
  performance: number;
  attendance: number;
  present: boolean | -1;
}

export const SchoolStudentModelConverter = {
  toFirestore: function (schoolStudentModel: SchoolStudentModel) {
    return {
      school_id: schoolStudentModel.school_id,
      user_id: schoolStudentModel.user_id,
      grade: schoolStudentModel.grade,
      section: schoolStudentModel.section,
      enrollment_number: schoolStudentModel.enrollment_number,
      roll: schoolStudentModel.roll,
      class_ref: schoolStudentModel.class_ref,
      created_at: schoolStudentModel.created_at
        ? Timestamp.fromDate(schoolStudentModel.created_at)
        : null,
      active: schoolStudentModel.active,
      deleted: schoolStudentModel.deleted,
      house_id: schoolStudentModel.house_id,
      team_id: schoolStudentModel.team_id,
      council_id: schoolStudentModel.council_id,
      features: schoolStudentModel.features,
    };
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const schoolStudentModel: SchoolStudentModel = {
      school_id: data['school_id'],
      user_id: data['user_id'],
      grade: data['grade'],
      section: data['section'],
      enrollment_number: data['enrollment_number'],
      roll: data['roll'],
      class_ref: data['class_ref'] || null,
      created_at: data['created_at'] ? data['created_at'].toDate() : null,
      active: data['active'],
      deleted: data['deleted'],
      house_id: data['house_id'],
      team_id: data['team_id'],
      council_id: data['council_id'],
      features: data['features'],
    };
    return schoolStudentModel;
  },
};
