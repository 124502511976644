import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor() {}

  private snackbar_: MatSnackBar = inject(MatSnackBar);

  private errorSnackbarConfig: MatSnackBarConfig = {
    panelClass: 'errorSnackbarClass',
    duration: 3000,
    horizontalPosition: 'center',
    verticalPosition: 'top',
  };

  private successSnackbarConfig: MatSnackBarConfig = {
    panelClass: 'successSnackbarClass',
    duration: 3000,
    horizontalPosition: 'center',
    verticalPosition: 'top',
  };

  openSuccessSnackbar(msg: string) {
    this.snackbar_.open(msg, '', this.successSnackbarConfig);
  }

  openErrorSnackbar(msg: string) {
    this.snackbar_.open(msg, '', this.errorSnackbarConfig);
  }
}
