import {
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from '@angular/fire/firestore';
import { UserModel } from './user.model';
import { SchoolTeacherAttendanceModel } from './school-teacher-attendance.model';

export interface SchoolTeacherModel {
  school_id: string;
  user_id: string;
  active: boolean;
  deleted: boolean;
  created_at: Date | null;
  classes: SchoolTeacherClassModel[];
  classes_taught: string[];
  subjects_taught: string[];
  students_taught: SchoolTeacherStudentTaughtModel[];
  teaching_hours: number;
  house_id: string;
  team_id: string;
  council_id: string;
}

export interface SchoolTeacherClassModel {
  grade: string;
  section: string;
  class_doc_id: string;
  subject: string;
  class_teacher: boolean;
}

export interface SchoolTeacherStudentTaughtModel {
  class_id: string;
  students_count: number;
}

export const SchoolTeacherModelConverter = {
  toFirestore: function (schoolTeacherModel: SchoolTeacherModel) {
    return {
      school_id: schoolTeacherModel.school_id!,
      user_id: schoolTeacherModel.user_id!,
      active: schoolTeacherModel.active,
      deleted: schoolTeacherModel.deleted,
      created_at: schoolTeacherModel.created_at
        ? Timestamp.fromDate(schoolTeacherModel.created_at)
        : null,
      classes: schoolTeacherModel.classes,
      classes_taught: schoolTeacherModel.classes_taught,
      subjects_taught: schoolTeacherModel.subjects_taught,
      students_taught: schoolTeacherModel.students_taught,
      teaching_hours: schoolTeacherModel.teaching_hours,
      house_id: schoolTeacherModel.house_id,
      team_id: schoolTeacherModel.team_id,
      council_id: schoolTeacherModel.council_id,
    };
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const schoolTeacherModel: SchoolTeacherModel = {
      school_id: data['school_id'],
      user_id: data['user_id'],
      active: data['active'],
      deleted: data['deleted'],
      created_at: data['created_at'] ? data['created_at'].toDate() : null,
      classes: data['classes'],
      classes_taught: data['classes_taught'],
      subjects_taught: data['subjects_taught'],
      students_taught: data['students_taught'],
      teaching_hours: data['teaching_hours'],
      house_id: data['house_id'],
      team_id: data['team_id'],
      council_id: data['council_id'],
    };
    return schoolTeacherModel;
  },
};

export interface SchoolTeacherDetailModel {
  teacher_doc: SchoolTeacherModel;
  teacher_details: UserModel;
  teacherAttendance?: SchoolTeacherAttendanceModel[];
}

export interface SchoolTeacherTable {
  name: string;
  subjects: string[];
  classes: string[];
  attendance: number;
  present: boolean | -1;
}

export interface SuperadminTeacherTable {
  name: string;
  gender: string;
  subjects: string[];
  mobile: string;
  school: string;
  attendance: number;
}
