import {
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from '@angular/fire/firestore';
import { SchoolStudentAttendanceDateModel } from './school-student-attendance.model';

export interface SchoolTeacherAttendanceModel {
  attendance_date: SchoolStudentAttendanceDateModel;
  created_at: Date;
  present: boolean;
  school_id: string;
  teacher_id: string;
}

export const SchoolTeacherAttendanceModelConverter = {
  toFirestore: function (attendanceModel: SchoolTeacherAttendanceModel) {
    return {
      attendance_date: attendanceModel.attendance_date, // Ensure SchoolStudentAttendanceDateModel is correctly handled
      created_at: Timestamp.fromDate(attendanceModel.created_at),
      present: attendanceModel.present,
      school_id: attendanceModel.school_id,
      teacher_id: attendanceModel.teacher_id,
    };
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const attendanceModel: SchoolTeacherAttendanceModel = {
      attendance_date: data['attendance_date'], // Ensure data['attendance_date'] matches SchoolStudentAttendanceDateModel structure
      created_at: data['created_at'].toDate(),
      present: data['present'],
      school_id: data['school_id'],
      teacher_id: data['teacher_id'],
    };
    return attendanceModel;
  },
};
