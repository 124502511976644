import {
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from '@angular/fire/firestore';
import { SchoolModelAddress } from './school.model';

export interface UserModel {
  name: string[];
  active: boolean;
  created_at: Date;
  email: string;
  mobile?: string;
  gender?: string;
  photo_url?: string;
  username?: string;
  dob?: Date | null;
  attempted_question?: number;
  attempted_question_correct?: number;
  attempted_assessment?: number;
  total_assessment_marks?: number;
  scored_assessment_marks?: number;
  assessment_uploaded?: number;
  homework_uploaded?: number;
  question_uploaded?: number;
  pdf_uploaded?: number;
  video_uploaded?: number;
  notes_uploaded?: number;
  experiment_uploaded?: number;
  math_solved?: number;
  math_practiced?: number;
  fav_subjects?: string[];
  areas_interest?: string[];
  method_study?: string[];
  book_categories?: string[];
  address?: SchoolModelAddress | null;
  language_learner?: UserLanguageScoreModel | null;
  docId?: string;
}

export interface UserLanguageScoreModel {
  score: number;
  level: number;
  total_words: number;
}

export const UserModelConverter = {
  toFirestore: function (userModel: UserModel) {
    return {
      name: userModel.name,
      email: userModel.email,
      created_at: Timestamp.fromDate(userModel.created_at),
      active: userModel.active,
      mobile: !!userModel.mobile ? userModel.mobile : '',
      gender: !!userModel.gender ? userModel.gender : '',
      photo_url: !!userModel.photo_url ? userModel.photo_url : '',
      username: !!userModel.username ? userModel.username : '',
      dob: !!userModel.dob ? Timestamp.fromDate(userModel.dob) : null,
      attempted_question: !!userModel.attempted_question
        ? userModel.attempted_question
        : 0,
      attempted_question_correct: !!userModel.attempted_question_correct
        ? userModel.attempted_question_correct
        : 0,
      attempted_assessment: !!userModel.attempted_assessment
        ? userModel.attempted_assessment
        : 0,
      total_assessment_marks: !!userModel.total_assessment_marks
        ? userModel.total_assessment_marks
        : 0,
      scored_assessment_marks: !!userModel.scored_assessment_marks
        ? userModel.scored_assessment_marks
        : 0,
      assessment_uploaded: !!userModel.assessment_uploaded
        ? userModel.assessment_uploaded
        : 0,
      homework_uploaded: !!userModel.homework_uploaded
        ? userModel.homework_uploaded
        : 0,
      question_uploaded: !!userModel.question_uploaded
        ? userModel.question_uploaded
        : 0,
      pdf_uploaded: !!userModel.pdf_uploaded ? userModel.pdf_uploaded : 0,
      video_uploaded: !!userModel.video_uploaded ? userModel.video_uploaded : 0,
      notes_uploaded: !!userModel.notes_uploaded ? userModel.notes_uploaded : 0,
      experiment_uploaded: !!userModel.experiment_uploaded
        ? userModel.experiment_uploaded
        : 0,
      math_solved: !!userModel.math_solved ? userModel.math_solved : 0,
      math_practiced: !!userModel.math_practiced ? userModel.math_practiced : 0,
      fav_subjects: !!userModel.fav_subjects ? userModel.fav_subjects : [],
      areas_interest: !!userModel.areas_interest
        ? userModel.areas_interest
        : [],
      method_study: !!userModel.method_study ? userModel.method_study : [],
      book_categories: !!userModel.book_categories
        ? userModel.book_categories
        : [],
      address: !!userModel.address ? userModel.address : null,
      language_learner: !!userModel.language_learner
        ? userModel.language_learner
        : null,
    };
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ) {
    const data = snapshot.data(options);

    const user: UserModel = {
      name: data['name'],
      gender: data['gender'],
      active: data['active'],
      created_at: data['created_at'],
      email: data['email'],
      mobile: data['mobile'],
      photo_url: data['photo_url'],
      username: data['username'],
      dob: data['dob'] != null ? data['dob'].toDate() : null,
      attempted_question: data['attempted_question'],
      attempted_question_correct: data['attempted_question_correct'],
      attempted_assessment: data['attempted_assessment'],
      total_assessment_marks: data['total_assessment_marks'],
      scored_assessment_marks: data['scored_assessment_marks'],
      assessment_uploaded: data['assessment_uploaded'],
      homework_uploaded: data['homework_uploaded'],
      question_uploaded: data['question_uploaded'],
      pdf_uploaded: data['pdf_uploaded'],
      video_uploaded: data['video_uploaded'],
      notes_uploaded: data['notes_uploaded'],
      experiment_uploaded: data['experiment_uploaded'],
      math_solved: data['math_solved'],
      math_practiced: data['math_practiced'],
      fav_subjects: data['fav_subjects'],
      areas_interest: data['areas_interest'],
      method_study: data['method_study'],
      book_categories: data['book_categories'],
      address: data['address'],
      language_learner: data['language_learner'],
      docId: snapshot.id,
    };

    return user;
  },
};
