import {
  DocumentReference,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from '@angular/fire/firestore';

export interface SchoolClassSyllabusModel {
  board: string;
  grade: string;
  medium: string;
  subject: string;
  created_at: Date;
  chapters: SchoolClassSyllabusChaptersModel[];
}

export interface SchoolClassSyllabusChaptersModel {
  chapter: string;
  chapter_no: number;
  period: number;
  period_taken: number;
  days: Date[];
  topics: SchoolClassSyllabusChaptersTopicModel[];
  completed: boolean;
}

export interface SchoolClassSyllabusChaptersTopicModel {
  topic: string;
  topic_no: number;
  completed: boolean;
  completed_timestamp?: Date | null;
  contents: SchoolClassSyllabusChaptersTopicContentModel[];
}

export interface SchoolClassSyllabusChaptersTopicContentModel {
  content_ref: DocumentReference;
  type: string;
  casted: boolean;
  casted_dates: Date[];
}

export const SchoolClassSyllabusModelConverter = {
  toFirestore: function (syllabusModel: SchoolClassSyllabusModel) {
    return {
      board: syllabusModel.board,
      grade: syllabusModel.grade,
      medium: syllabusModel.medium,
      subject: syllabusModel.subject,
      created_at: Timestamp.fromDate(syllabusModel.created_at),
      chapters: syllabusModel.chapters.map((chapter) => ({
        chapter: chapter.chapter,
        chapter_no: chapter.chapter_no,
        period: chapter.period,
        period_taken: chapter.period_taken,
        days: chapter.days.map((day) => Timestamp.fromDate(day)),
        topics: chapter.topics.map((topic) => ({
          topic: topic.topic,
          topic_no: topic.topic_no,
          completed: topic.completed,
          completed_timestamp: topic.completed_timestamp
            ? Timestamp.fromDate(topic.completed_timestamp)
            : null,
          contents: topic.contents.map((content) => ({
            content_ref: content.content_ref,
            type: content.type,
            casted: content.casted,
            casted_dates:
              content.casted_dates != null
                ? content.casted_dates.map((date) => Timestamp.fromDate(date))
                : [],
          })),
        })),
        completed: chapter.completed,
      })),
    };
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const syllabusModel: SchoolClassSyllabusModel = {
      board: data['board'],
      grade: data['grade'],
      medium: data['medium'],
      subject: data['subject'],
      created_at: data['created_at'].toDate(),
      chapters: data['chapters'].map((chapter: any) => ({
        chapter: chapter['chapter'],
        chapter_no: chapter['chapter_no'],
        period: chapter['period'],
        period_taken: chapter['period_taken'],
        days: chapter['days'].map((day: any) => day.toDate()),
        topics: chapter['topics'].map((topic: any) => ({
          topic: topic['topic'],
          topic_no: topic['topic_no'],
          completed: topic['completed'],
          completed_timestamp: topic['completed_timestamp']
            ? topic['completed_timestamp'].toDate()
            : null,
          contents: topic['contents'].map((content: any) => ({
            content_ref: content['content_ref'],
            type: content['type'],
            casted: content['casted'],
            casted_dates: !!content['casted_dates']
              ? content['casted_dates'].map((date: any) => date.toDate())
              : [],
          })),
        })),
        completed: chapter['completed'],
      })),
    };
    return syllabusModel;
  },
};
