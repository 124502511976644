import { SchoolTimetableDaysModel } from './school-timetable.model';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from '@angular/fire/firestore';
import { SchoolModelCurriculum } from './school.model';
import { SchoolStudentAssessmentModel } from './school-student-assessment.model';
import { SchoolStudentAttendanceModel } from './school-student-attendance.model';
import { SchoolStudentModel } from './school-student.model';
import { SchoolClassSyllabusModel } from './school-class-syllabus.model';

export interface SchoolClassModel {
  active: boolean;
  deleted: boolean;
  name: string;
  section: string;
  medium: string;
  board: string;
  subjects: string[];
  created_at: Date;
  curriculum: SchoolModelCurriculum;
  teachers: SchoolClassTeacherModel[];
  docRef?: DocumentReference;
}

export interface SchoolClassTeacherModel {
  subject: string;
  teacher_id: string;
  class_teacher: boolean;
}

export interface SchoolClassData {
  class_details: SchoolClassModel;
  class_assessments: SchoolStudentAssessmentModel[];
  class_attendance: SchoolStudentAttendanceModel[];
  class_syllabus: SchoolClassSyllabusModel[];
}

export interface SchoolClassTable {
  class_name: string;
  students: number;
  performance: number;
  attendance: number;
  class_ref: DocumentReference;
  today_attendance: number;
}

export interface SchoolClassDashboardModel {
  class_students: SchoolStudentModel[];
  class_assessements: SchoolStudentAssessmentModel[];
  class_attendance: SchoolStudentAttendanceModel[];
  class_timetable: SchoolTimetableDaysModel[];
}

export interface SubjectwiseClassStats {
  subject: string;
  performance: number;
  syllabusProgress: number;
  contentCasted: number;
  teacher_name: string;
}

export interface ClassStudentsTable {
  name: string;
  roll: number;
  performance: number;
  attendance: number;
  present: boolean | -1;
}

export const SchoolClassModelConverter = {
  toFirestore: function (schoolClassModel: SchoolClassModel) {
    return {
      active: schoolClassModel.active,
      deleted: schoolClassModel.deleted,
      name: schoolClassModel.name,
      section: schoolClassModel.section,
      medium: schoolClassModel.medium,
      board: schoolClassModel.board,
      subjects: schoolClassModel.subjects,
      created_at: Timestamp.fromDate(schoolClassModel.created_at),
      curriculum: schoolClassModel.curriculum,
      teachers: schoolClassModel.teachers,
    };
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const schoolClassModel: SchoolClassModel = {
      active: data['active'],
      deleted: data['deleted'],
      name: data['name'],
      section: data['section'],
      medium: data['medium'],
      board: data['board'],
      subjects: data['subjects'],
      created_at: data['created_at'].toDate(),
      curriculum: data['curriculum'],
      teachers: data['teachers'],
      docRef: snapshot.ref,
    };
    return schoolClassModel;
  },
};
