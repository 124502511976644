import {
  Timestamp,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from '@angular/fire/firestore';
import { UserModel } from './user.model';

export interface SchoolAdminModel {
  user_id: string;
  school_id: string;
  role: string;
  active: boolean;
  deleted: boolean;
  created_at: Date;
}

export interface SchoolAdminDetailModel {
  admin_doc: SchoolAdminModel;
  admin_details: UserModel;
}

export interface SuperadminAdminTable {
  name: string;
  gender: string;
  mobile: string;
  school: string;
}

export const SchoolAdminModelConverter = {
  toFirestore: function (schoolAdminModel: SchoolAdminModel) {
    return {
      user_id: schoolAdminModel.user_id!,
      school_id: schoolAdminModel.school_id!,
      role: schoolAdminModel.role!,
      active: schoolAdminModel.active,
      deleted: schoolAdminModel.deleted,
      created_at: Timestamp.fromDate(schoolAdminModel.created_at),
    };
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const schoolAdminModel: SchoolAdminModel = {
      user_id: data['user_id'],
      school_id: data['school_id'],
      role: data['role'],
      active: data['active'],
      deleted: data['deleted'],
      created_at: data['created_at'].toDate(),
    };
    return schoolAdminModel;
  },
};
