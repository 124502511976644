import {
  DocumentReference,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from '@angular/fire/firestore';

export interface SchoolStudentAttendanceModel {
  attendance_date: SchoolStudentAttendanceDateModel;
  class_ref: DocumentReference;
  roll: number;
  grade: string;
  section: string;
  student_id: string;
  school_id: string;
  teacher_id: string;
  present: boolean;
}

export interface SchoolStudentAttendanceDateModel {
  day: number;
  month: number;
  year: number;
}

export const SchoolStudentAttendanceModelConverter = {
  toFirestore: function (attendance: SchoolStudentAttendanceModel) {
    return {
      attendance_date: attendance.attendance_date,
      class_ref: attendance.class_ref,
      roll: attendance.roll,
      grade: attendance.grade,
      section: attendance.section,
      student_id: attendance.student_id,
      school_id: attendance.school_id,
      teacher_id: attendance.teacher_id,
      present: attendance.present,
    };
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): SchoolStudentAttendanceModel {
    const data = snapshot.data(options);
    const attendance: SchoolStudentAttendanceModel = {
      attendance_date: data['attendance_date'],
      class_ref: data['class_ref'],
      roll: data['roll'],
      grade: data['grade'],
      section: data['section'],
      student_id: data['student_id'],
      school_id: data['school_id'],
      teacher_id: data['teacher_id'],
      present: data['present'],
    };
    return attendance;
  },
};
